import Progress from '../../../components/external/Progress'
import dialogDetail from './components/dialogDetail'
import GlobalMixin from '../../mixin/index'
export default {
  mixins:[GlobalMixin],
  data () {
    return {
      user_edit: null,
      dialog_delete:false,
      dialog_detail:false,
      data_detail:{},
      from:new Date().toISOString().substr(0, 10),
      to:new Date().toISOString().substr(0, 10),
      from_menu:false,
      to_menu:false,
      branch_id:'',
      total:0,
    }
  },
  components: {
    Progress,
    dialogDetail
  },
  methods: {
    openDetail: function (item = {}) {
      this.data_detail = item
      this.dialog_detail = !this.dialog_detail
    },
    async go (table,store) {
      await this.axios.get('/v1/'+ table +'?company_id=' + this.user.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
        .then(async (ress) => {
          await this.setData({ data: ress.data.data, table_name: store })
        })
        .catch(err => console.log(err))
      this.loading = false
    },
    close () {
      this.user_edit = null
      this.dialog_delete = false
      this.dialog_detail = false
      this.dialog_form = false
    },
    tgl_sales_fc(value) {
      let time = new Date(value)
      let tgl = parseInt(time.getDate()) < 10 ? "0" + time.getDate() : time.getDate()
      let bln = time.getMonth() + 1 < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1
      let jam =  time.getHours() < 10 ? "0" +  time.getHours() :  time.getHours()
      let menit =  time.getUTCMinutes() <= 9 ? "0" +  time.getUTCMinutes().toString() :  time.getUTCMinutes()
      let date = `${bln}-${tgl}-${time.getFullYear()} ` + jam + ":" + menit
      return date
    },
    async grand_total(data) {
      this.total = 0
      await data.forEach(async x => {
        await x.transaction_details.forEach(element => {
          this.total = this.total + parseInt(element.price * element.qty)

        })
      })

    }
  },
  filters:{
    tgl_sales:function(value) {
      let time = new Date(value)
      let tgl = parseInt(time.getDate()) < 10 ? "0" + time.getDate() : time.getDate()
      let bln = time.getMonth() + 1 < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1
      let jam =  time.getHours() < 10 ? "0" +  time.getHours() :  time.getHours()
      let menit =  time.getUTCMinutes() <= 9 ? "0" +  time.getUTCMinutes().toString() :  time.getUTCMinutes()
      let date = `${bln}-${tgl}-${time.getFullYear()} ` + jam + ":" + menit
      return date
    },
    sub_total:function(value) {
      let sub_total = 0
      value.forEach(element => {
        sub_total = sub_total + parseInt(element.price)
      });
      return parseInt(sub_total)
    }
  }
}
