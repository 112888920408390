<template>
  <div class="text-center" >
    <v-dialog
      v-model="dialog"
      max-width="950px"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          Detail Puchase
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-simple-table>
              <template v-slot:default>
                  <tr>
                    <td class="text-left">
                      Document
                    </td>
                    <td class="text-left" colspan="2">
                      {{data.doc_no}}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      Date
                    </td>
                    <td class="text-left" colspan="2">
                      {{data.doc_date}}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      Name
                    </td>
                    <td class="text-left" colspan="2">
                      {{data.name}}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      Payment
                    </td>
                    <td class="text-left" colspan="2">
                      {{data.payment.name}}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      Branch
                    </td>
                    <td class="text-left" colspan="2">
                      {{data.branch.name}}
                    </td>
                  </tr>

                  <tr>
                    <td :rowspan="data.transaction_details.length + 2">Transaction Details</td>
                  </tr>
                  <tr>
                    <td>product</td>
                    <td>Subtotal</td>
                  </tr>
                  <tr v-for="item in data.transaction_details" :key="item.id">
                    <td>
                      {{item.product.name}} = {{item.qty}} x Rp. {{item.price | numeral('0,0')}}
                    </td>
                    <td class="text-right">
                      Rp.{{item.qty * parseInt(item.price) | numeral('0,0')}}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">Total</td>
                    <td class="text-right"> Rp.{{total_belanja| numeral('0,0')}}</td>
                  </tr>

              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="css" scoped>
    table {
      border-collapse: collapse;

    }
    table, th, td {
    border: 1px solid #efefef;
    }
    td{
      padding:10px
    }
  /* .table tr td, .table tr th {
      padding: 10px;
      text-align: left;
  }
    .table tr th {
        background-color:#f5f5f5;
        color: black;
    } */
</style>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: [ 'data'],
  components:{
  },
  methods: {
    close () {
      this.$emit('close')
    },
  },
  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    dialog:true,
  }),

  computed: {
    ...mapGetters({
      color: 'layout/color',
    }),
    backgroundColor () {
      return `background-color:${this.color} !important`
    },

    total_belanja() {
      let total = 0
      this.data.transaction_details.forEach(element => {
        total = total + (element.qty * element.price)
      });
      return total
    }

  },

  async created () {
  }
}
</script>
