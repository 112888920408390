<template>
  <div>
    <Progress v-if="loading"/>
    <v-card
    tile
    v-if="!loading"
    >
    <v-card-text class="text-center">
        <v-container>
            <v-row justify="center" align="center">
                <v-col
                    cols="9"
                >
                <v-row>
                  <v-col cols="4">
                    <v-menu
                      v-model="from_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="from"
                          label="From"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="from"
                        @input="from_menu = false"
                        @change="go_data()"
                        :max="to"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      v-model="to_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="to"
                          label="To"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="to"
                        @input="to_menu = false"
                        @change="go_data()"
                        :min="from"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                    :menu-props="{contentClass: 'dd-list-branch'}"
                    :items="branchs"
                    v-model="branch_id"
                    label="Branch"
                    item-value="id"
                    item-text="name"
                    @input="go_data()"
                  ></v-select>
                  </v-col>
                </v-row>
                </v-col>

                <v-col
                    cols="3"
                    align="right"
                >
                <download-excel :data="data" style="display:inline-block"
                :title="'Report Sales from ' + from + ' to ' + to" :name="'Report Sales from ' + from + ' to ' + to +'.xls'">
                <v-btn color="success" small depressed class="mr-2">
                  <v-icon small>mdi-microsoft-excel</v-icon>
                </v-btn>
                </download-excel>
                          <v-btn color="red" small depressed @click="downloadPdf()">
                        <v-icon small color="white">mdi-file-pdf-outline</v-icon>
                    </v-btn>

                </v-col>
            </v-row>
        </v-container>
        <h3 class="text-left">Grand Total : Rp. {{ this.total | numeral("0,0") }}</h3>
        <br>
        <v-data-table
          :headers="headers"
          :items="sales"
          :page.sync="page"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          mobile-breakpoint="0"
          @click:row="openDetail"
        >
        <template v-slot:item.entry_date="{ item }">
            {{item.entry_date | tgl_sales}}
          </template>
         <template v-slot:item.total_total="{ item }">
            Rp. {{item.total - item.discount | numeral("0,0")}}
          </template>
          <template v-slot:item.total="{ item }">
            Rp. {{item.total | numeral("0,0")}}
          </template>

        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          ></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
    </v-card-text>
    </v-card>
    <DetailDialog :data="data_detail" v-if="dialog_detail" v-on:close="openDetail"/>
    <vue-html2pdf
      ref="html2Pdf"
      :paginate-elements-by-height="1400"
      pdf-content-width="1100px"
      pdf-orientation="landscape"
      :filename="`Report Sales form ${from} to ${to}`"
    >
    <templatePDF :headers="['Branch', 'Doc Date', 'Doc No', 'Sales Time (date & time)', 'Discount', 'Total', 'Sales Type', 'Customer']"
    :title="`Report Sales from ${from} to ${to}`"
    slot="pdf-content"
     >

    <tr v-for="item in sales" :key="item.id">
      <td>{{item.branch.name}}</td>
      <td>{{item.doc_date}}</td>
      <td>{{item.doc_no}}</td>
      <td>{{item.entry_date | tgl_sales}}</td>
      <td>{{item.discount}}</td>
      <td>Rp. {{item.total  | numeral("0,0")}}</td>
      <td>{{item.doc_type}}</td>
      <td>{{item.name}}</td>
      <td>
        <ul>
          <li v-for="detai in item.Details" :key="detai.product_id"> {{detai.product.name}} = {{detai.qty}}</li>
        </ul>
      </td>
    </tr>
    </templatePDF>
    </vue-html2pdf>
    <dialogDetail :data="data_detail" v-if="dialog_detail" v-on:close="close"/>

  </div>
</template>

<script>
// @ is an alias to /src
import Mixin from './mixin'
import {mapGetters} from 'vuex'
import downloadExcel from "vue-json-excel";
import VueHtml2pdf from 'vue-html2pdf'
import templatePDF from '../../../components/external/report-pdf'
export default {
  mixins:[Mixin],
  data() {
    return {
      data:[],
      pdf_data:'',
      headers: [
        {
          text: 'Branch',
          align: 'start',
          value: 'branch.name'
        },
        {
          text: 'Doc Date',
          value: 'doc_date'
        },
        {
          text: 'Doc No',
          value: 'doc_no'
        },

        {
          text: 'Sales Time (date & time)',
          value: 'entry_date'
        },
        {
          text: 'Sub Total',
          value: 'total'
        },
         {
          text: 'Discount',
          value: 'discount'
        },
        {
          text: 'Sales Type',
          value: 'sales_type.name'
        },
        {
          text: 'Payment Type',
          value: 'payment.name'
        },
        {
          text: 'Total',
          value: 'total_total'
        },
        {
          text: 'Customer',
          value: 'name'
        },
      ]
    }
  },
  components: {
    downloadExcel,VueHtml2pdf,templatePDF,
  },
  computed: {
    ...mapGetters({
      sales: 'data/sales',
      branchs: 'data/branchs',
    }),
  },
  methods:{
    async go_data () {
      await this.axios.get('/v1/sales/report?company_id=' + this.user.company_id + `&branch_id=${this.branch_id}&from=${this.from}&to=${this.to}` ,)
        .then(async (ress) => {
          console.log(ress.data.data)
          await this.setData({ data: ress.data.data, table_name: 'sales' })
          this.data = await this.sales.map(x => {
            let time = new Date(x.entry_date)
            let value = {
              Branch:x.branch.name,
              Document:x.doc_no,
              Date:x.doc_date,
              Sales_Time:this.tgl_sales_fc(x.entry_date),
              Sub_total:x.total,
              Discount:x.discount,
              Sales_Type:x.sales_type.name,
              Payment_type:x.payment.name,
              total:x.total - x.discount,

              Customer:x.name
            }

            return value
          })

          await this.grand_total(this.sales)

        })
        .catch(err => console.log(err))
          console.log(this.total)

      this.loading = false
    },
     async downloadPdf() {
      this.dialog_loading = true
      await this.$refs.html2Pdf.generatePdf();
      this.dialog_loading = false
    },
  },
  async created() {
    if (this.sales.length) {
      this.loading = false
    }
  },
  async mounted () {
    await this.go('branch/all','branchs')
    this.branch_id = this.user.branch_id
    await this.go_data()

  }
}
</script>
